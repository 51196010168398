// debugDisplay.js

// -------------------------
// DEBUG/DISPLAY FUNCTIONS
// -------------------------

// Get a reference to your debug div
const debugDiv = document.getElementById("debug");

// Keep track of logged messages
const loggedMessages = new Set();

// Utility function to append messages to the debug display
export function appendDebugMessage(message) {
  if (debugDiv && !loggedMessages.has(message)) {
    debugDiv.innerHTML += `${message}<br />`;
    loggedMessages.add(message);
  }
}

// Wrap the original console functions
const originalLog = console.log;
const originalError = console.error;
const originalWarn = console.warn;

// Override console.log
/* console.log = function (...args) {
  const message = args.join(" ");

  // Filter out heartbeat messages
  if (message.includes("heartbeat")) {
    return; // Skip logging heartbeat messages
  }

  if (!loggedMessages.has(message)) {
    originalLog.apply(console, args);
    appendDebugMessage(message);
  }
}; */

// Override console.error
/* console.error = function (...args) {
  const message = `Error: ${args.join(" ")}`;

  if (!loggedMessages.has(message)) {
    originalError.apply(console, args);
    appendDebugMessage(message);
  }
}; */

// Override console.warn (optional)
console.warn = function (...args) {
  const message = `Warning: ${args.join(" ")}`;

  if (!loggedMessages.has(message)) {
    originalWarn.apply(console, args);
    appendDebugMessage(message);
  }
};
